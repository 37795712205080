<template>
  <div>
    <div class="header"></div>
    <img src="~@/assets/news.jpg" class="img" />
  </div>
</template>

<script>
export default {
  name: 'News'
};
</script>

<style lang="scss" scoped>
.img {
  width: 100vw;
}
</style>
